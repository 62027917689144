import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Надійний Дім - Ваша агенція нерухомості
			</title>
			<meta name={"description"} content={"Надійний Дім - професійна допомога в купівлі, продажу та оренді нерухомості. Забезпечуємо якісні послуги для вашого комфорту."} />
			<meta property={"og:title"} content={"Надійний Дім - Ваша агенція нерухомості"} />
			<meta property={"og:description"} content={"Надійний Дім - професійна допомога в купівлі, продажу та оренді нерухомості. Забезпечуємо якісні послуги для вашого комфорту."} />
			<meta property={"og:image"} content={"https://worldwisd.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://worldwisd.com/img/6835685.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://worldwisd.com/img/6835685.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://worldwisd.com/img/6835685.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://worldwisd.com/img/6835685.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://worldwisd.com/img/6835685.png"} />
			<meta name={"msapplication-TileImage"} content={"https://worldwisd.com/img/6835685.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-green" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--headline2"
							color="--dark"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Більше про нас
						</Text>
						<Text as="h2" margin="12px 0" font="--lead" md-font="--headline3">
						Місія Надійний Дім полягає в наданні високоякісних послуг в сфері нерухомості. Ми прагнемо зробити процес купівлі, продажу та оренди нерухомості максимально простим і безпечним для наших клієнтів. Наша команда завжди готова допомогти вам на кожному етапі угоди.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://worldwisd.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://worldwisd.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="normal 900 38px/1.2 --fontFamily-sans"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Історія агенції
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Надійний Дім була заснована групою досвідчених фахівців, які бачили потребу в надійній і професійній агенції нерухомості. З моменту заснування ми допомогли тисячам клієнтів знайти свої ідеальні будинки та комерційні приміщення. Ми пишаємося нашими досягненнями та плануємо продовжувати рости і розвиватися.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
			<Text
				margin="0px 0px 24px 0px"
				color="--dark"
				font="normal 900 38px/1.2 --fontFamily-sans"
				lg-text-align="center"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
			>
				Станьте частиною нашої історії&nbsp;успіху!
			</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				У Надійний Дім працюють тільки висококваліфіковані спеціалісти. Наші агенти мають великий досвід роботи на ринку нерухомості, що дозволяє нам забезпечити найвищий рівень обслуговування. Ми постійно підвищуємо кваліфікацію наших співробітників, щоб бути в курсі всіх новинок та змін на ринку.
				<br/><br/>
				Основні цінності Надійний Дім – це чесність, прозорість та професіоналізм. Ми завжди діємо в інтересах наших клієнтів і забезпечуємо максимальну безпеку угод. Наша мета – стати вашим надійним партнером у сфері нерухомості.
				<br/><br/>
				Ми віримо, що бізнес має не тільки приносити прибуток, але й бути корисним для суспільства. Надійний Дім активно підтримує соціальні проекти і благодійні ініціативи. Ми прагнемо зробити свій внесок у розвиток нашої громади і допомогти тим, хто потребує підтримки.
				<br/><br/>
				Довіртеся Надійний Дім і скористайтеся нашими професійними послугами у сфері нерухомості. Ми готові допомогти вам знайти ідеальне житло або продати вашу власність за найвигіднішими умовами.
				</Text>
				<Link
					href="/contact"
					padding="12px 40px 12px 40px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
					target="_blank"
					align-self="center"
				>
					Зв'яжіться з нами
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://worldwisd.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});